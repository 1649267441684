import i18n from '@/plugins/i18n'
import * as types from '@/store/mutation-types'
import { isPast } from 'date-fns'
import { store } from '@/store'
//import router from '@/router'

export const formatErrorMessages = (translationParent, msg) => {
  const errorArray = []
  // Check for error msgs
  if (msg !== null) {
    const json = JSON.parse(JSON.stringify(msg))
    // If error message is an array, then we have mutiple errors
    if (Array.isArray(json)) {
      json.map(error => {
        errorArray.push(i18n.t(`${translationParent}.${error.msg}`))
      })
    } else {
      // Single error
      errorArray.push(i18n.t(`${translationParent}.${msg}`))
    }
    return errorArray
  }
  // all good, return null
  return null
}

export const buildPayloadPagination = (pagination, search) => {

  //console.log('pagination: '+JSON.stringify(pagination,null,2))
  // console.log('search: '+JSON.stringify(search,null,2))

  const { eventId, sortBy, page, itemsPerPage } = pagination
  let { descending } = pagination.sortDesc[0]
  // Gets order
  //descending = descending ? -1 : 1 // ANTIGO
  descending = pagination.sortDesc[0] ? -1 : 1 // ALTERADO POR ROGÉRIO EM 10/04/2020
  let query = {}

  if(search.status){ // campos para a busca de withdraws
    query = {
      eventId: eventId,
      sort: sortBy,
      order: descending,
      page,
      limit: itemsPerPage,
      // filter: search.query,
      // fields: search.fields,
      // query: search,
      status: search.status,
      key: search.searchTerm
    }

  }else if (search && search.status == null) { // If search and fields are defined

    query = {
      eventId: eventId,
      sort: sortBy,
      order: descending,
      page,
      limit: itemsPerPage,
      filter: search.query,
      fields: search.fields,
      query: search,
    }

  } else {
    // Pagination with no filters
    query = {
      eventId: eventId,
      sort: sortBy,
      order: descending,
      page,
      limit: itemsPerPage
    }
  }
  //console.log('buildPayloadPagination query: '+JSON.stringify(query, null, 2))
  return query
}

// Catches error connection or any other error (checks if error.response exists)
export const handleError = (error, commit, reject) => {

  console.log('util error: '+JSON.stringify(error,null,2))

  let errMsg = ''
  // Resets errors in store
  // commit(types.SHOW_LOADING, false)
  commit(types.SHOW_BTN_LOADING, false)
  commit(types.SHOW_TOP_LOADING, false)
  commit(types.SHOW_CENTER_LOADING, false)
  commit(types.ERROR, null)

  // Checks if unauthorized
  if (error.response !== undefined && error.response.status === 401) {
    errMsg = error.response.data.errors.msg
    store.dispatch('userLogout')
  } else {
    // Any other error
    errMsg = error.response
      ? error.response.data.errors.msg
      : 'SERVER_TIMEOUT_CONNECTION_ERROR'
  }

  setTimeout(() => {
    return errMsg
      ? commit(types.ERROR, errMsg)
      : commit(types.SHOW_ERROR, false)
  }, 0)
  reject(error)
}

export const buildSuccess = (
  msg,
  commit,
  resolve,
  resolveParam = undefined
) => {
  commit(types.SHOW_BTN_LOADING, false)
  commit(types.SHOW_TOP_LOADING, false)
  commit(types.SHOW_CENTER_LOADING, false)
  commit(types.SUCCESS, null)
  setTimeout(() => {
    return msg ? commit(types.SUCCESS, msg) : commit(types.SHOW_SUCCESS, false)
  }, 0)
  commit(types.ERROR, null)
  resolve(resolveParam)
}

// Checks if tokenExpiration in localstorage date is past, if so then trigger an update
export const checkIfTokenNeedsRefresh = () => {
  //console.log('tokenExpiration: '+new Date(JSON.parse(window.localStorage.getItem('tokenExpiration')) * 1000))
  if (
    window.localStorage.getItem('token') !== null &&
    window.localStorage.getItem('tokenExpiration') !== null &&
    isPast(new Date(JSON.parse(window.localStorage.getItem('tokenExpiration')) * 1000))
  ) {
      //router.go()
      store.dispatch('refreshToken')
    }
}
