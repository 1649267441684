import axios from 'axios'

export default {
  getPartners(params){
    return axios.get(`/users/admin/list?role=partner`, { params })
  },

  getExistingPartners(params){
    return axios.get(`/users/admin/list?role=partner,promoter`, { params })
  },

  getPartner(payload){
    return axios.get(`/users/admin/list?role=partner&_id=${payload._id}`)
  },
  registerPartner(payload){
    return axios.post(`/users/admin/register`, payload)
  },
  updatePartner(payload){
    return axios.patch(`/users/admin/update`, payload)
  },
  deletePartner(payload){
    return axios.delete(`/users/admin/`, {data: payload})
  },

  registerCode(payload){
    return axios.post(`/promocodes/admin/`, payload)
  },
  getCodes(params){
    return axios.get(`/promocodes/admin/list/${params._id}`)
  },
  updateCode(payload){
    return axios.patch(`/promocodes/admin/`, payload)
  },

  getPartnersWithdraws(params){
    return axios.get(`/promocodes/admin/withdraws`, {
      params
    })
  },
  updatePartnerWithdraw(payload){
    return axios.patch('/promocodes/admin/withdraws', payload)
  }

}
