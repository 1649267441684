import api from '@/services/api/users'
import * as types from '@/store/mutation-types'
import { handleError, buildSuccess } from '@/utils/utils.js'
// import router from '@/router'

const getters = {
  userId: state => state.userId,
  userData: state => state.userData,
  banks: state => state.banks
}

const actions = {

  getUser({ commit }, payload){
    commit(types.SHOW_CENTER_LOADING, true)
    return new Promise((resolve, reject) => {
      api
        .getUser(payload)
        .then(response => {
          //console.log(JSON.stringify(response,null,2))
          if (response.status === 200) {
            commit(types.USER_DATA, response.data)
            commit(types.SHOW_CENTER_LOADING, false)
            resolve(true)
          }
        })
        .catch(error => {
          handleError(error, commit, reject)
        })
    })
  },
  getBanks({ commit }){
    return new Promise((resolve, reject) => {
      api
        .getBanks()
        .then(response => {
          if (response.status === 200) {
            commit(types.BANKS, response.data.docs)
            resolve(true)
          }
        })
        .catch(error => {
          handleError(error, commit, reject)
        })
    })
  },

  updateUser({ commit }, payload){
    commit(types.SHOW_CENTER_LOADING, true)
    return new Promise((resolve, reject) => {
      api
        .updateUser(payload)
        .then(response => {
          if(response.status === 200) {
            commit(types.SHOW_CENTER_LOADING, false)
            buildSuccess(
              { msg: 'common.SAVED_SUCCESSFULLY' },
              commit,
              resolve
            )

          }
        })
        .catch(error => {
          commit(types.SHOW_CENTER_LOADING, false)
          handleError(error, commit, reject)
        })
    })
  }

}

const mutations = {
  [types.USER_ID](state, value) {
    state.userId = value
  },
  [types.USER_DATA](state, value) {
    state.userData = value
  },
  [types.BANKS](state, value) {
    state.banks = value
  },
}

const state = {
  userId: null,
  emailExists: false,
  userData: null,
  banks: null
}

export default {
  state,
  getters,
  actions,
  mutations
}
