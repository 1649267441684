// app
export const SET_APP_VERSION = '0.1b'
// locale
export const SET_LOCALE = 'SET_LOCALE'
// error
export const SHOW_ERROR = 'SHOW_ERROR'
export const ERROR = 'ERROR'
// success
export const SHOW_SUCCESS = 'SHOW_SUCCESS'
export const SUCCESS = 'SUCCESS'
// loading
export const SHOW_CENTER_LOADING = 'SHOW_CENTER_LOADING'
export const SHOW_TOP_LOADING = 'SHOW_TOP_LOADING'
export const SHOW_BTN_LOADING = 'SHOW_BTN_LOADING'
export const SITE_IS_LOADING = 'SITE_IS_LOADING'
// auth
export const IS_LOGGED_IN = 'IS_LOGGED_IN'
export const SAVE_TOKEN = 'SAVE_TOKEN'
export const SAVE_USER = 'SAVE_USER'
export const SAVE_EVENTS = 'SAVE_EVENTS'
export const LOGOUT = 'LOGOUT'
// user
export const BANKS = 'BANKS'
export const USER_DATA = 'USER_DATA'
export const USER_ID = 'USER_ID'
// events
export const EVENTS = 'EVENTS'
export const TOTAL_EVENTS = 'TOTAL_EVENTS'
export const EVENT = 'EVENT'
export const EVENT_TRANSACTIONS = 'EVENT_TRANSACTIONS'
export const EVENT_TRANSACTIONS_REPORT = 'EVENT_TRANSACTIONS_REPORT'
export const BANNER_UPLOADED = 'BANNER_UPLOADED'
export const UPLOAD_PERCENT = 'UPLOAD_PERCENT'
export const FILES = 'FILES'
// loans
export const LOANS = 'LOANS'
export const TOTAL_LOANS = 'TOTAL_LOANS'
export const LOAN_STATUS = 'LOAN_STATUS'
// withdraws
export const WITHDRAWS = 'WITHDRAWS'
export const TOTAL_WITHDRAWS = 'TOTAL_WITHDRAWS'
export const WITHDRAWS_REPORT = 'WITHDRAWS_REPORT'
export const PURCHASES = 'PURCHASES'
// transactions
export const TRANSACTIONS = 'TRANSACTIONS'
export const TOTAL_TRANSACTIONS = 'TOTAL_TRANSACTIONS'
// dash
export const DASH = 'DASH'
// gifts
export const GIFT = 'GIFT'
export const GIFTS = 'GIFTS'
export const TOTAL_GIFTS = 'TOTAL_GIFTS'
export const GID = 'GID'
// partners and codes
export const PARTNER = 'PARTNER'
export const PARTNERS = 'PARTNERS'
export const TOTAL_PARTNERS = 'TOTAL_PARTNERS'
export const PID = 'PID'
export const CODES = 'CODES'
export const TOTAL_CODES = 'TOTAL_CODES'
export const PARTNERS_WITHDRAWS = 'PARTNERS_WITHDRAWS'
export const PARTNERS_WITHDRAWS_TOTAL = 'PARTNERS_WITHDRAWS_TOTAL'
export const PARTNERS_WITHDRAWS_REPORT = 'PARTNERS_WITHDRAWS_REPORT'
