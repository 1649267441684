export default [
  {
    path: '/withdraws',
    name: 'financial-withdraws',
    meta: {
      requiresAuth: true,
      title: 'Resgates de Promotoras'
    },
    component: () =>
      import(/* webpackChunkName: "profile" */ '@/components/financial/Withdraws.vue')
  },
  {
    path: '/transactions',
    name: 'financial-transactions',
    meta: {
      requiresAuth: true,
      title: 'Transações'
    },
    component: () =>
      import(/* webpackChunkName: "profile" */ '@/components/financial/Transactions.vue')
  },
  {
    path: '/partnersWithdraws',
    name: 'partners-withdraws',
    meta: {
      requiresAuth: true,
      title: 'Resgates de Parceiras'
    },
    component: () =>
      import(/* webpackChunkName: "profile" */ '@/components/financial/Partners.vue')
  },
]
