import api from '@/services/api/partners'
import * as types from '@/store/mutation-types'
import { handleError, buildSuccess } from '@/utils/utils.js'

const getters = {
  partners: state => state.partners,
  totalPartners: state => state.totalPartners,
  partner: state => state.partner,
  pId: state => state.pId,
  codes: state => state.codes,
  totalCodes: state => state.totalCodes,
  partnersWithdraws: state => state.partnersWithdraws,
  partnersWithdrawsTotal: state => state.partnersWithdrawsTotal,
  partnersWithdrawsReport: state => state.partnersWithdrawsReport
}

const actions = {
  getPartners({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit(types.SHOW_CENTER_LOADING, true)
      api
        .getPartners(payload)
        .then(response => {
          if (response.status === 200) {
            commit(types.PARTNERS, response.data.docs)
            commit(types.TOTAL_PARTNERS, response.data.totalDocs)
            commit(types.SHOW_CENTER_LOADING, false)
            resolve(true)
          }
        }).catch(error => {
          //console.log('getEvents error: '+JSON.stringify(error,null,2))
          commit(types.SHOW_CENTER_LOADING, false)
          handleError(error, commit, reject)
        })
    })
  },

  getExistingPartners({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit(types.SHOW_CENTER_LOADING, true)
      api
        .getExistingPartners(payload)
        .then(response => {
          if (response.status === 200) {
            commit(types.PARTNERS, response.data.docs)
            commit(types.TOTAL_PARTNERS, response.data.totalDocs)
            commit(types.SHOW_CENTER_LOADING, false)
            resolve(true)
          }
        }).catch(error => {
          //console.log('getEvents error: '+JSON.stringify(error,null,2))
          commit(types.SHOW_CENTER_LOADING, false)
          handleError(error, commit, reject)
        })
    })
  },

  getPartner({ commit }, payload) {

    return new Promise((resolve, reject) => {
      commit(types.SHOW_CENTER_LOADING, true)
      api
        .getPartner(payload)
        .then(response => {

          //console.log('getPartner response: '+JSON.stringify(response,null,2))

          if (response.status === 200) {
            commit(types.PARTNER, response.data.docs[0])
            commit(types.SHOW_CENTER_LOADING, false)
            resolve(true)
          }
        }).catch(error => {
          console.log('getPartner error: '+JSON.stringify(error,null,2))
          commit(types.SHOW_CENTER_LOADING, false)
          handleError(error, commit, reject)
        })
    })
  },

  updatePartner({ commit }, payload) {

    return new Promise((resolve, reject) => {
      commit(types.SHOW_CENTER_LOADING, true)
      api
        .updatePartner(payload)
        .then(response => {
          //console.log('updateEvent response: '+JSON.stringify(response,null,2))
          if (response.status === 200) {
            commit(types.PID, response.data._id)
            commit(types.SHOW_CENTER_LOADING, false)
            buildSuccess(
              { msg: 'common.SAVED_SUCCESSFULLY' },
              commit,
              resolve
            )
          }
        }).catch(error => {
          //console.log('getEvent error: '+JSON.stringify(error,null,2))
          commit(types.SHOW_CENTER_LOADING, false)
          handleError(error, commit, reject)
        })
    })
  },

  registerPartner({ commit }, payload) {

    return new Promise((resolve, reject) => {
      commit(types.SHOW_CENTER_LOADING, true)
      api
        .registerPartner(payload)
        .then(response => {
          console.log('registerPartner response: '+JSON.stringify(response,null,2))
          if (response.status === 200) {
            commit(types.PID, response.data._id)
            commit(types.SHOW_CENTER_LOADING, false)
            buildSuccess(
              { msg: 'common.SAVED_SUCCESSFULLY' },
              commit,
              resolve
            )
          }
        }).catch(error => {
          //console.log('registerPartner error: '+JSON.stringify(error,null,2))
          commit(types.SHOW_CENTER_LOADING, false)
          handleError(error, commit, reject)
        })
    })
  },

  deletePartner({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit(types.SHOW_CENTER_LOADING, true)
      api
        .deletePartner(payload)
        .then(response => {

          if (response.status === 200) {
            commit(types.SHOW_CENTER_LOADING, false)
            buildSuccess(
              { msg: 'common.DELETED_SUCCESSFULLY' },
              commit,
              resolve
            )
          }
        }).catch(error => {
          console.log('delete error: '+JSON.stringify(error,null,2))
          handleError(error, commit, reject)
        })
    })
  },

  registerCode({ commit }, payload) {

    return new Promise((resolve, reject) => {
      commit(types.SHOW_CENTER_LOADING, true)
      api
        .registerCode(payload)
        .then(response => {
          console.log('registerCode response: '+JSON.stringify(response,null,2))
          if (response.status === 200) {
            commit(types.SHOW_CENTER_LOADING, false)
            buildSuccess(
              { msg: 'common.SAVED_SUCCESSFULLY' },
              commit,
              resolve
            )
          }
        }).catch(error => {
          console.log('registerCode error: '+JSON.stringify(error,null,2))
          commit(types.SHOW_CENTER_LOADING, false)
          handleError(error, commit, reject)
        })
    })
  },

  getCodes({ commit }, payload) {
    //console.log('getCodes payload: '+JSON.stringify(payload,null,2))
    return new Promise((resolve, reject) => {
      commit(types.SHOW_CENTER_LOADING, true)
      api
        .getCodes(payload)
        .then(response => {
          if (response.status === 200) {

            commit(types.CODES, response.data)
            commit(types.TOTAL_CODES, response.data.length)
            commit(types.SHOW_CENTER_LOADING, false)
            resolve(true)
          }
        }).catch(error => {
          //console.log('getEvents error: '+JSON.stringify(error,null,2))
          commit(types.SHOW_CENTER_LOADING, false)
          handleError(error, commit, reject)
        })
    })
  },

  updateCode({ commit }, payload) {

    return new Promise((resolve, reject) => {
      commit(types.SHOW_CENTER_LOADING, true)
      api
        .updateCode(payload)
        .then(response => {
          if (response.status === 200) {
            commit(types.SHOW_CENTER_LOADING, false)
            buildSuccess(
              { msg: 'common.SAVED_SUCCESSFULLY' },
              commit,
              resolve
            )
          }
        }).catch(error => {
          commit(types.SHOW_CENTER_LOADING, false)
          handleError(error, commit, reject)
        })
    })
  },

  getPartnersWithdraws({ commit }, payload) {
    return new Promise(( resolve, reject ) => {
      commit( types.SHOW_CENTER_LOADING, true )
      api
        .getPartnersWithdraws(payload)
        .then( response => {
          if ( response.status === 200 ) {
            commit( types.PARTNERS_WITHDRAWS, response.data.docs )
            commit( types.PARTNERS_WITHDRAWS_TOTAL, response.data.totalDocs )
            commit( types.SHOW_CENTER_LOADING, false )
            resolve(true)
          }
        }).catch(error => {
          commit(types.SHOW_CENTER_LOADING, false)
          handleError(error, commit, reject)
        })
    })
  },

  updatePartnerWithdraw( {commit}, payload ) {
    return new Promise( (resolve, reject) => {
      commit ( types.SHOW_CENTER_LOADING, true )
      api
        .updatePartnerWithdraw(payload)
        .then( response => {
          if( response.status === 200 ){
            commit(types.SHOW_CENTER_LOADING, false)
            buildSuccess(
              { msg: 'common.SAVED_SUCCESSFULLY' },
              commit,
              resolve
            )
          }
        }).catch(error => {
          commit(types.SHOW_CENTER_LOADING, false)
          handleError(error, commit, reject)
        })
    })
  }

}

const mutations = {
  [types.PARTNERS](state, value) {
    state.partners = value
  },
  [types.TOTAL_PARTNERS](state, value) {
    state.totalPartners = value
  },
  [types.PARTNER](state, value) {
    state.partner = value
  },
  [types.PID](state, value) {
    state.pId = value
  },
  [types.CODES](state, value) {
    state.codes = value
  },
  [types.TOTAL_CODES](state, value) {
    state.totalCodes = value
  },
  [types.PARTNERS_WITHDRAWS](state, value) {
    state.partnersWithdraws = value
  },
  [types.PARTNERS_WITHDRAWS_TOTAL](state, value) {
    state.partnersWithdrawsTotal = value
  },
  [types.PARTNERS_WITHDRAWS_REPORT](state, value) {
    state.partnersWithdrawsReport = value
  },
}

const state = {
  partners: [],
  totalPartners: 0,
  partner: [],
  pId: null,
  codes: [],
  totalCodes: 0,
  partnersWithdraws: [],
  partnersWithdrawsTotal: 0,
  partnersWithdrawsReport: []
}

export default {
  state,
  getters,
  actions,
  mutations
}
