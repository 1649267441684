import axios from 'axios'

export default {
  getBanks(){
    return axios.get('/users/banks')
  },

  getUser(params){
    return axios.get(`/users/admin/${params.userId}`)
  },

  updateUser(payload){
    return axios.patch('/users/admin/update', payload)
  }

}
